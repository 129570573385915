import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { CompanyEntity } from '@deecision/dna-interfaces';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { CompanyGetter, getEntityPath } from '../../providers/getter';
import KeyValueList from '@/components/keyvalue/keyvaluelist';
import CompanyEntitiesChips from '@/main/containers/chips/company';
import CompanyWidgetsIdentity from '@/main/containers/widgets/companies/identity';

function CompanySmallCards(props: { entity: CompanyEntity, chips?: ReactElement[] }): ReactElement {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const path = getEntityPath({ ...props.entity }, true);
  const companyGetter = new CompanyGetter({
    data: props.entity,
    errorMsg: t('common.utils.unknown')
  });

  return (
    <Grid
      container
      width='100%'
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(path)}
      spacing={1}
    >
      <Grid size={12} container spacing={2} minWidth='min-content' alignItems='center' sx={{ rowGap: 1 }}>
        <Grid>
          <Typography
            variant='subtitle2'
            maxWidth='100%'
            whiteSpace='break-spaces'
            overflow='hidden'
            textOverflow='ellipsis'
            color='primary'
            sx={{
              textDecoration: 'underline',
              textDecorationColor: lighten(theme.palette.primary.main, 0.5),
              '&:hover': {
                color: darken(theme.palette.primary.main, 0.1),
                textDecorationColor: darken(theme.palette.primary.main, 0.1)
              }
            }}
          >
            {companyGetter.getName()}
          </Typography>
        </Grid>
        {props.chips && props.chips.length > 0 ?
          props.chips.map(chip => (
            <Grid key={chip.props.key}>
              {chip}
            </Grid>
          )) : null
        }
        {CompanyEntitiesChips({ entity: props.entity, size: 'small' })
          .filter(chip => chip.key !== 'common.dates.lastUpdate' && chip.key !== 'state' && chip.key !== 'completenessTitleChip' && chip.key !== 'common.identity.country')
        }
      </Grid>
      <Grid size={12} container spacing={2} direction='row' wrap='nowrap'>
        <Grid
          size='grow'
          minWidth='min-content'
          sx={{
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '& ::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <CompanyWidgetsIdentity data={props.entity} simple small />
        </Grid>
        <Grid>
          <Divider orientation='vertical' />
        </Grid>
        <Grid>
          <KeyValueList values={[[
            {
              key: t('common.financial.turnover.short'),
              fullKey: t('common.financial.turnover.label'),
              value: companyGetter.getTurnover(),
              align: 'right' as const
            },
            {
              key: t('common.financial.capital.label'),
              value: companyGetter.getCapital(),
              align: 'right' as const
            },
            {
              key: t('common.financial.ebitda.label'),
              value: companyGetter.getEbitda(),
              align: 'right' as const
            }
          ]]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompanySmallCards;
